import React from 'react';

import { RouteInput } from './route-input';

type SearchResult = {
  lat: number;
  lng: number;
  name: string;
  osm_id: number;
  osm_type: string;
};

type RouteFormProps = {
  city: string;
};

export const RouteForm = (props: RouteFormProps) => {
  const setLocation = (opts: any) => {
    if (opts && opts.length > 0) {
      document.location = opts[0].uri;
    }
  };

  return (
    <form className="row g-3 justify-content-center">
      <div className="col-md-12">
        <div className="input-group">
          <RouteInput city={props.city} onSelect={setLocation} />
        </div>
      </div>
    </form>
  );
};

import React, {useState} from 'react';

import {AsyncTypeahead} from 'react-bootstrap-typeahead';

const SEARCH_URI = '/api/search/route?';

interface RouteInputProps {
  onSelect: (res: any) => void;
  city: string;
}

export const RouteInput = (props: RouteInputProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query: string) => {
    setIsLoading(true);
    const params = new URLSearchParams();
    params.append('q', query);
    params.append('city', props.city);
    const url = SEARCH_URI + params;
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => resp.response)
      .then(({results}) => {
        setOptions(results);
        setIsLoading(false);
      });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <>
      <AsyncTypeahead
        filterBy={filterBy}
        id="route-search"
        isLoading={isLoading}
        labelKey="name"
        minLength={1}
        onChange={props.onSelect}
        onSearch={handleSearch}
        options={options}
        placeholder="Örn: 500T"
        renderMenuItemChildren={(option: any) => (
          <>
            <span className="typeahead-option">
              {option.code} - {option.name}
            </span>
          </>
        )}
        renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
          <input
            {...inputProps}
            className="form-control form-control-lg me-1 pe-5 yt-search-box"
            type="text"
            ref={(input: HTMLInputElement) => {
              inputRef(input);
              referenceElementRef(input);
            }}
          />
        )}
      />
    </>
  );
};

import React from 'react';
import {createRoot} from 'react-dom/client';
import {RouteForm} from './route-form';
import {SearchForm} from './form';

type MainSearchFormOpts = {
  q: string;
  osmId: string;
  name: string;
  city: string;
};

export class MainSearchForm {
  init(elementId: string, opts: MainSearchFormOpts) {
    const element = document.getElementById(elementId);

    const root = createRoot(element!);
    root.render(
      <SearchForm
        q={opts.q}
        osmId={opts.osmId}
        name={opts.name}
        city={opts.city}
      />,
    );
  }
}

export class RouteSearchForm {
  init(elementId: string, city: string) {
    const element = document.getElementById(elementId);

    const root = createRoot(element!);
    root.render(<RouteForm city={city} />);
  }
}

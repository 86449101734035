import React, {useState} from 'react';

import {LocationInput} from './location-input.tsx';

type SearchResult = {
  lat: number;
  lng: number;
  name: string;
  osm_id: number;
  osm_type: string;
};

type SearchFormProps = {
  q: string;
  osmId: string;
  name: string;
  city: string;
};

export const SearchForm = (props: SearchFormProps) => {
  const [location, setLocation] = useState(
    [] as Array<SearchResult | undefined>,
  );

  let name = props.name;
  let osmId = props.osmId;
  let city = props.city;
  if (location != null && location.length > 0) {
    name = location[0]!.name;
    osmId = `${location[0]!.osm_type}-${location[0]!.osm_id}`;
  }

  return (
    <form
      className="row g-3 justify-content-center"
      action="/nasil-gidilir"
      method="GET"
    >
      <input type="hidden" name="loc" value={osmId} />
      <input type="hidden" name="nerde" value={name} />
      <input type="hidden" name="sehir" value={city} />

      <div className="col-md-5">
        <div className="input-group">
          <input
            className="form-control form-control-lg me-1 pe-5"
            autoComplete='off'
            type="text"
            name="q"
            defaultValue={props.q}
            placeholder="Ne arıyorsunuz?"
          />
        </div>
      </div>
      <div className="col-md-5">
        <div className="input-group">
          <LocationInput
            defaultValue={name}
            city={props.city}
            onSelect={setLocation}
          />
        </div>
      </div>
      <div className="col-md-2 d-grid">
        <button className="btn btn-lg btn-primary" type="submit">
          Ara
        </button>
      </div>
    </form>
  );
};

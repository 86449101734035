import React, {useState} from 'react';

import {AsyncTypeahead} from 'react-bootstrap-typeahead';

const SEARCH_URI = '/api/search?';

interface LocationInputProps {
  onSelect: (res: any) => void;
  defaultValue: string;
  city: string;
}

export const LocationInput = (props: LocationInputProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query: string) => {
    setIsLoading(true);
    const params = new URLSearchParams();
    params.append('q', query);
    params.append('type', 'location');
    if (props.city) {
      params.append('city', props.city);
    }
    const url = SEARCH_URI + params;
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => resp.response)
      .then(({results}) => {
        setOptions(results);
        setIsLoading(false);
      });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <>
      <AsyncTypeahead
        filterBy={filterBy}
        id="async-example"
        isLoading={isLoading}
        labelKey="name"
        minLength={3}
        onChange={props.onSelect}
        onSearch={handleSearch}
        options={options}
        defaultInputValue={props.defaultValue}
        placeholder="Nerde?"
        renderMenuItemChildren={(option: any) => (
          <>
            <span className="typeahead-option">{option.name}</span>
          </>
        )}
        renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
          <input
            {...inputProps}
            autoComplete='off'
            className="form-control form-control-lg me-1 pe-5"
            type="text"
            ref={(input: HTMLInputElement) => {
              inputRef(input);
              referenceElementRef(input);
            }}
          />
        )}
      />
    </>
  );
};
